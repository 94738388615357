import styled, { createGlobalStyle } from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';
import { StyledFullWidthWrapper } from '@src/styles/mainLayoutWrapper';

import { StyledButton } from '../FormComponent/styles';

export const StyledOuter = styled(StyledFullWidthWrapper)<{ $isSticky?: boolean }>`
  ${({ $isSticky }) =>
    $isSticky
      ? `
  position: sticky;
  top: 0px;
  z-index:9;
  `
      : ''}
`;

export const StyledHeaderContainer = styled.div<{ $headerBackGround?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space.get('l')};
  padding: 8px calc(${({ theme }) => theme.space.get('2xl')}*3.1);
  border-bottom: 1px solid ${({ theme }) => theme.primaryColor.denseGray};
  ${Breakpoints.DESKTOP} {
    padding: 8px calc(${({ theme }) => theme.space.get('2xl')}*2);
  }
  ${Breakpoints.DESKTOP_SMALL} {
    padding: 16px ${({ theme }) => theme.space.get('s')};
  }
  ${Breakpoints.TABLET} {
    padding: 12px 16px;
  }
  ${Breakpoints.MOBILE} {
    padding: 8px 12px;
  }
  background-color: ${({ theme, $headerBackGround }) => $headerBackGround || theme.primaryColor.white};
`;

export const StyledNavTabs = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.get('l')};
  padding: ${({ theme }) => theme.space.get('s')} 0;
  ${Breakpoints.DESKTOP_SMALL} {
    display: none;
  }
`;

export const StyledNavTab = styled.div`
  color: ${({ theme }) => theme.primaryColor.shadePurple};
  text-align: center;
  font-size: 16px;
  ${Breakpoints.DESKTOP_SMALL} {
    font-size: 18px;
  }

  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primaryColor.fluentBlue};
  }
`;

export const StyledLoginButtonContainer = styled.div<{ $mobileView?: boolean; $bgColor?: string }>`
  color: ${({ theme }) => theme.primaryColor.shadePurple};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  &:hover {
    color: ${({ theme }) => theme.primaryColor.fluentBlue};
  }
  ${Breakpoints.DESKTOP_SMALL} {
    display: ${({ $mobileView }) => ($mobileView ? 'flex' : 'none')};
    font-size: 18px;
    ${({ $mobileView, theme }) =>
      $mobileView
        ? `
      padding: 16px 20px;
      background-color:${theme.primaryColor.shadePurple};
      text-align:center;
      color:white;
      width:-webkit-fill-available;
      border-radius: 6px;
      justify-content: center;
      width: -moz-available;`
        : ''}
  }
`;

export const StyledLoginButton = styled.div<{ $mobileView?: boolean; $bgColor?: string }>`
  color: ${({ theme }) => theme.primaryColor.shadePurple};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.primaryColor.fluentBlue};
  }
  ${Breakpoints.DESKTOP_SMALL} {
    display: ${({ $mobileView }) => ($mobileView ? 'flex' : 'none')};
    font-size: 18px;
    ${({ $mobileView, theme }) =>
      $mobileView
        ? `
      padding: 16px 20px;
      background-color:${theme.primaryColor.shadePurple};
      text-align:center;
      color:white;
      width:-webkit-fill-available;
      border-radius: 6px;
      justify-content: center;
      width: -moz-available;`
        : ''}
  }
`;

export const StyledContact = styled.div`
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.primaryColor.shadePurple};
  border-radius: 6px;
  color: ${({ theme }) => theme.primaryColor.shadePurple};
  font-weight: 400;
`;

export const StyledDropdownMenuWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100vh;
  display: ${props => (props.isVisible ? '' : 'none')};
  transform: translateY(${props => (props.isVisible ? '0' : '-10px')});
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1010;
  background: ${({ theme }) => theme.primaryColor.white};

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const StyledDropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 8px ${({ theme }) => theme.space.get('2xs')};
  ${Breakpoints.DESKTOP_SMALL} {
    gap: 1rem;
  }
`;

export const StyledDropdownItem = styled.div<{ $isSelected: boolean }>`
  color: ${({ $isSelected, theme }) => ($isSelected ? theme.primaryColor.fluentBlue : theme.primaryColor.shadePurple)};
  font-size: ${({ theme }) => theme.fontSize.get('l')};
  cursor: pointer;
  font-weight: 600;
  width: calc(100% - 10px);
  border-bottom: ${({ $isSelected, theme }) => ($isSelected ? `3px solid ${theme.primaryColor.royalBlue}` : 'none')};

  &:hover {
    color: ${({ theme }) => theme.primaryColor.fluentBlue};
  }
`;

export const StyledHamburgerIcon = styled.div`
  display: none;

  ${Breakpoints.DESKTOP_SMALL} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledHamburgerImage = styled.img`
  cursor: pointer;
  width: 25px;
  padding: ${({ theme }) => theme.space.get('2xs')};
  border: 1px solid #aec7d7;
  border-radius: 10px;
`;

export const StyledDropdownMenuItem = styled.div`
  position: relative;
  ${Breakpoints.DESKTOP_SMALL} {
    position: unset;
  }
`;
export const StyledMoreOptionsDropdown = styled.div`
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  top: 40px;
  border-radius: 8px;
  min-width: 300px;
  left: -150px;
  padding: ${({ theme }) => theme.space.get('s')};
  z-index: 3;
  ${Breakpoints.DESKTOP_SMALL} {
    position: static;
    top: 0;
    border-radius: none;
    background-color: ${({ theme }) => theme.primaryColor.lightGrey};
    box-shadow: none;
    padding: 14px;
    min-width: 200px;
  }
`;

export const StyledMoreOptionsItem = styled.div`
  font-size: 16px;
  font-family: 'Lato', Lato;
  font-weight: 600;
  padding: ${({ theme }) => theme.space.get('2xs')};
  cursor: pointer;
  color: #0f222e;
  border-radius: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.primaryColor.azure};
  }
  ${Breakpoints.DESKTOP_SMALL} {
    font-size: 20px;
  }
`;

export const StyledMoreButton = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: ${({ show, theme }) => (show ? theme.primaryColor.shadePurple : theme.primaryColor.fluentBlue)};
  ${Breakpoints.DESKTOP_SMALL} {
    width: 97vw;
    display: flex;
    justify-content: space-between;
    color: ${({ theme }) => theme.primaryColor.shadePurple};
    font-size: 20px;
  }
  &:hover {
    color: ${({ theme }) => theme.primaryColor.fluentBlue};
  }
`;

export const GlobalStyle = createGlobalStyle`
  body.activeDropdown {
    overflow: hidden !important;
  }
`;

export const DefaultProfileIcon = styled.div`
  margin-right: 10px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: ${props => props.theme.primaryColor.navyBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.primaryColor.white};
  font-size: ${({ theme }) => theme.fontSize.get('m')};
  text-transform: uppercase;
`;

export const StyledProfileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
`;

export const StyledUserName = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  font-weight: 700;
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
`;

export const StyledUserRole = styled.span`
  color: ${({ theme }) => theme.primaryColor.slateShadow};
  font-size: 14px;
`;

export const StyledProfileInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const StyleProfileContainer = styled.div<{ $mobileView?: boolean }>`
  display: flex;
  align-items: center;
  gap: 24px;
  cursor: pointer;
  ${Breakpoints.DESKTOP_SMALL} {
    display: ${({ $mobileView }) => ($mobileView ? 'block' : 'none')};
  }
`;

export const StyledBlastLogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
`;

export const StyledCustomLink = styled.div`
  color: ${({ theme }) => theme.primaryColor.shadePurple};
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
  ${Breakpoints.DESKTOP_SMALL} {
    font-size: 20px;
    font-family: ${({ theme }) => theme.fontFamily.lato};
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const StyledCloseButton = styled(StyledButton)`
  padding: 9px 13px;
  margin: 0;
  font-size: 15px;
  border: 1px solid ${({ theme }) => theme.primaryColor.sereneSky};
`;

export const StyledImage = styled.img`
  height: 38px;
  ${Breakpoints.DESKTOP_SMALL} {
    height: 42px;
  }
`;
